/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    strong: "strong",
    p: "p",
    h4: "h4",
    a: "a",
    hr: "hr",
    h3: "h3",
    img: "img",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, React.createElement(_components.strong, null, "Formas de criar sua conta Vendoor")), "\n", React.createElement(_components.p, null, "Existe ", React.createElement(_components.strong, null, "três formas"), " de criar sua conta na Vendoor e cada uma delas vai gerar um resultado diferente do seu currículo cadastrado portanto leia com atenção antes de decidir qual você vai realizar."), "\n", React.createElement(_components.h4, null, "Pelo WhatsApp (Forma mais descomplicada)"), "\n", React.createElement(_components.p, null, "Você consegue criar um cadastro apenas conversando com nosso agente de Whatsapp! Para esse tipo de cadastro você vai receber algumas perguntas e seu perfil no final vai conter apenas suas informações essenciais e seu currículo anexado."), "\n", React.createElement(_components.h4, null, React.createElement(_components.a, {
    href: "https://wa.me/5562999888552"
  }, "Me cadastrar pelo WhatsApp")), "\n", React.createElement(_components.h4, null, "Pelo Portal de Vendas (Forma mais rápida)"), "\n", React.createElement(_components.p, null, "Ao acessar uma vaga pelo portal de vagas Vendoor você tem a possibilidade de fazer um cadastro com informações semelhantes ao do WhatsApp porem em forma de formulário. Você também será redirecionado para dentro da plataforma do Vendoor onde pode completar mais informações e continuar com mais candidaturas."), "\n", React.createElement(_components.h4, null, React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/"
  }, "Ir para o Portal de Vagas")), "\n", React.createElement(_components.h4, null, "Pela Plataforma Vendoor (Currículo mais completo)"), "\n", React.createElement(_components.p, null, "O cadastro através da plataforma Vendoor contém mais informações, resultando em um perfil mais rico e completo, com maiores chances de sucesso dentro da nossa plataforma. Ao se cadastrar pela Dashboard da Vendoor, você preencherá não apenas as informações básicas, como em outros cadastros, mas também poderá selecionar suas competências relevantes, registrar suas experiências anteriores, realizar seu teste DISC, gravar um áudio de apresentação, entre outros."), "\n", React.createElement(_components.h4, null, React.createElement(_components.a, {
    href: "https://dashboard.vendoor.me/auth/signup"
  }, "Me cadastrar pela plataforma Vendoor")), "\n", React.createElement("br"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, null, React.createElement(_components.strong, null, "Qual a melhor forma de me cadastrar?")), "\n", React.createElement(_components.p, null, "Não há uma única melhor forma, apenas a que for mais adequada para você! No entanto, após se cadastrar por uma das duas primeiras alternativas, é essencial acessar a plataforma novamente para completar seu perfil. Isso aumentará suas chances de sucesso na busca por emprego."), "\n", React.createElement("br"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, null, "Como preencher seu cadastro pela plataforma da Vendoor"), "\n", React.createElement(_components.h3, null, "Primeiro Passo"), "\n", React.createElement(_components.p, null, "Aprenda como fazer o seu cadastro completo na nossa plataforma, comece criando sua conta, ", React.createElement(_components.a, {
    href: "https://dashboard.vendoor.me/auth/signup"
  }, "clique aqui"), " para ser direcionado para o link de criação de conta de ", React.createElement(_components.strong, null, "candidato.")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, null, "Insira suas principais informações pessoais:"), "\n", React.createElement("figure", null, React.createElement(_components.img, {
    src: "/0b2ce42ff8bb1a5b6c5e3580497a3fe1/tutorial-cadastro-1.gif",
    alt: "Insira suas informações pessoais"
  }), React.createElement("figcaption", null, React.createElement(_components.p, null, "Utilize uma foto com boa iluminação, com vestimenta apropriada e expressão natural. Adicione seu nome completo e\nse possível já adicione seu currículo em anexo nessa página. Caso não tenha um pronto no momento você pode\nadicionar depois de criar um, para facilitar ", React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/blog/como-preparar-seu-curriculo"
  }, "acesse aqui um tutorial com modelos de currículo e crie\nagora"), ".")), React.createElement(_components.hr), React.createElement(_components.h3, null, "Confirme seu WhatsApp:")), "\n", React.createElement("figure", null, React.createElement(_components.img, {
    src: "/90886898eeb0f34fd8227f12eaa2b5c9/tutorial-cadastro-2.gif",
    alt: "Insira seu whatsapp"
  }), React.createElement("figcaption", null, React.createElement(_components.p, null, "Ao acionar o botão de receber código, em alguns segundos você receberá no aplicativo do WhatsApp um número de 6\ndígitos para confirmar nessa tela. Seu whatsapp é uma forma de ser contatado pelo recrutador, receber\nnotificações do sistema e também poderá ser utilizado para fazer login.")), React.createElement(_components.hr), React.createElement(_components.h3, null, "Adicione suas informações de login:")), "\n", React.createElement("figure", null, React.createElement(_components.img, {
    src: "/33e98bbb882d324fa3b521b47ee69146/tutorial-cadastro-3.gif",
    alt: "Insira seu e-mail e senha"
  }), React.createElement("figcaption", null, React.createElement(_components.p, null, "Seu e-mail também receberá atualizações de seu processo e para login você poderá utilizar tanto email+senha\nquanto código no WhatsApp.")), React.createElement(_components.hr), React.createElement(_components.h3, null, "Insira o endereço da sua casa:")), "\n", React.createElement("figure", null, React.createElement(_components.img, {
    src: "/21bd44703c3fa204c82028c088b38d6c/tutorial-cadastro-4.gif",
    alt: "Insira seu e-mail e senha"
  }), React.createElement("figcaption", null, React.createElement(_components.p, null, "Na plataforma Vendoor seu endereço é uma das formas de traçar as melhores vagas disponíveis para você. Buscamos\nencontrar a vaga que você quer, mais perto de você.")), React.createElement(_components.hr), React.createElement(_components.h3, null, "Cadastre mais informações no seu perfil:")), "\n", React.createElement("figure", null, React.createElement(_components.img, {
    src: "/84c887e94ed9a50dbe43625e82293d32/tutorial-cadastro-5.gif",
    alt: "Descreva suas experiências e habilidades"
  }), React.createElement("figcaption", null, React.createElement(_components.p, null, "Esse campo de texto te permite um espaço para descrever suas experiências com mais detalhes, falar sobre você e\nse apresentar para os recrutadores da plataforma. Seu texto além de servir como apresentação também ajuda seu\nperfil a chegar em mais pessoas, portanto capriche!")), React.createElement(_components.hr), React.createElement(_components.h3, null, "Cadastre mais informações no seu perfil:")), "\n", React.createElement("figure", null, React.createElement(_components.img, {
    src: "/83c5b8f4dae2f5e58a778085960c4ca1/tutorial-cadastro-6.gif",
    alt: "Adicione um audio para se apresentar"
  }), React.createElement("figcaption", null, React.createElement(_components.p, null, "Lembre-se que esse audio irá aparecer para todas as vagas que você se candidatar, portanto não fale detalhes\nsobre uma vaga específica e aborde temas relacionado a sua carreira e experiência. Hoje 9 em cada 10 das nossas\ncontratações são de pessoas que gravaram audio e essa é uma das formas que o recrutador mais gosta de avaliar\nnos candidatos antes de marcar uma entrevista.")), React.createElement(_components.hr), React.createElement(_components.h3, null, "Indicamos completar seu perfil com mais informações não obrigatórias:")), "\n", React.createElement("figure", null, React.createElement(_components.img, {
    src: "/b60db8c88f36b3e576b693efec936355/tutorial-cadastro-7.gif",
    alt: "Informações adicionais"
  }), React.createElement("figcaption", null, React.createElement(_components.p, null, "Quanto mais informações seu perfil tiver, mais fácil nosso sistema consegue gerar compatibilidade com vagas.\nPara adicionar mais informações você pode acessar através do ", React.createElement(_components.strong, null, "banner"), " de completar perfil que aparece na\n", React.createElement(_components.strong, null, "home"), " do seu aplicativo. Você também pode acessar diretamente no perfil, através do menu ", React.createElement(_components.strong, null, "outros"), ".")), React.createElement(_components.hr), React.createElement(_components.h3, null, "Adicionando experiências e habilidades:")), "\n", React.createElement("figure", null, React.createElement(_components.img, {
    src: "/ddc8aebb290c90c30836c7b3180da64e/tutorial-cadastro-8.gif",
    alt: "Informações adicionais"
  }), React.createElement("figcaption", null, React.createElement(_components.p, null, "Você pode selecionar ou buscar por experiências/habilidades cadastradas na plataforma e clicar para\nselecionar\ntodas que você possuir. Não é necessário selecionar todas habilidades semelhantes, ao selecionar uma o seu\nperfil já é relacionado com as similares."))), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, null, "Pronto! Comece a se candidatar agora!"), "\n", React.createElement(_components.p, null, "Agora você já pode começar a utilizar a plataforma e ver suas vagas recomendadas na aba inicial. Se tiver outras\ndúvidas você pode entrar em contato através do suporte dentro do sistema ou buscar no ", React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/blog/"
  }, "nosso\nblog"), " por mais tutoriais."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h4, null, "Créditos"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Autor do artigo:"), " Lisanna Reis\n", React.createElement("br"), React.createElement(_components.em, null, "Atualizado pela última vez em"), " 17/02/2025"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
